import './Error.css';

function Error() {
  return (
    <div className="text-center error-box">
        <div className="error-message"><p>&#x1f30d;</p>This link has expired.</div>
    </div>
  );
}

export default Error;
