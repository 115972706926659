import './Create.css';
import { useState } from 'react';
import * as Base32URL from 'base32url';

function Create() {
  const [lat, setLat] = useState(0);
  const [lng, setLng] = useState(0);

  const changeLat = (latChgn) => {
    setLat(latChgn.target.value);
  }
  const changeLng = (lngChgn) => {
    setLng(lngChgn.target.value);
  }

  const encodeCoordinate = (crd) => {
    var enc = parseFloat(crd);
    enc = enc ? enc : 0.0;
    enc = enc >= -180 ? enc : 0.0;
    enc = enc <= 180 ? enc : 0.0;
    enc = Math.exp(Math.sqrt(Math.log(enc+255)));
    enc = String(Math.floor(Math.random() * 100000))+"|"+String(enc)+"|"+String(Math.floor(Math.random() * 1000000));
    return Base32URL.encode(enc);
  }

  return (
    <div className="create-box">
      <p>Lat: <input value={lat} type="text" onChange={changeLat} /></p>
      <p>Lng: <input value={lng} type="text" onChange={changeLng} /></p>
      <code>
        {window.location.origin}/street/{encodeCoordinate(lat)}/{encodeCoordinate(lng)}
      </code>
    </div>
  );
}

export default Create;
