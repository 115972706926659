import './Map.css';
import crosshair from './crosshair.svg';

import React, { useState, useEffect } from 'react';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import copy from 'copy-to-clipboard';

const containerStyle = {
  width: '100vw',
  height: '100vh'
};

function Map(props) {
  const [markerLat, setMarkerLat] = useState(51.4942692);
  const [markerLng, setMarkerLng] = useState(-0.1266201);
  const [markerText, setMakerText] = useState("51.4942692;-0.1266201");
  const [copied, setCopied] = useState(false);
  const [updatedTitle, setUpdatedTitle] = useState(false);

  useEffect(() => {
    if(!updatedTitle) {
      document.title = "Geo - Location Picker";
      setUpdatedTitle(true);
    }
  }, [setUpdatedTitle, updatedTitle]);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyBP6aK_63-OMwBoGKDlBrrXyLmjLm4LtVY"
  });

  const updateLocation = (mouseEvent) => {
    const markerlocation = mouseEvent.latLng.toJSON();
    const markerTextUpdate = parseFloat(markerlocation.lat).toFixed(7) + ";" + parseFloat(markerlocation.lng).toFixed(7);
    setMarkerLat(markerlocation.lat);
    setMarkerLng(markerlocation.lng);
    setMakerText(markerTextUpdate);
    copy(markerTextUpdate);
    if(!copied) {
      setCopied(true);
    }
  };

  return isLoaded ? (
    <div>
      <div className="overlay">
        <div className="text-box">
          <h1>Click anywhere on the map to place the marker:</h1>
          <img src={crosshair} alt="crosshair" />
          <input type="text" size="24" value={markerText} readOnly/>
          <small className={copied ? "overlay-status" : "overlay-status hidden"}>Copied to clipboard!</small>
        </div>
      </div>
      <div className="map">
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={{
            lat: markerLat,
            lng: markerLng
          }}
          zoom={3}
          onClick={updateLocation}
          options={{
            "fullscreenControl": false,
            "streetViewControl": false,
            "draggableCursor": "crosshair",
            "clickableIcons": false
          }}
        >
          <Marker
            position={{
              lat: markerLat,
              lng: markerLng
            }}
            options={{
              "draggable": false
            }}
            onDrag={updateLocation}
          />
        </GoogleMap>
      </div>
    </div>
  ) : <></>
}

export default React.memo(Map)