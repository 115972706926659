import './App.css';
import { Switch, Route } from "wouter";
import Streetview from "./Streetview";
import Map from "./Map";
import Create from "./Create";
import Error from "./Error";

function App() {
  return (
    <div className="App">
      <Switch>
        <Route path="/create" component={Create}></Route>
        <Route path="/map" component={Map}></Route>
        <Route path="/street/:lat/:lng" component={Streetview}></Route>
        <Route default component={Error}></Route>
      </Switch>
    </div>
  );
}

export default App;
