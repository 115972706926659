import './Streetview.css';

import React, {useEffect, useState} from 'react';
import { GoogleMap, StreetViewPanorama, useJsApiLoader } from '@react-google-maps/api';
import * as Base32URL from 'base32url';

const containerStyle = {
  width: '100vw',
  height: '100vh'
};

const decodeCoordinate = (enc) => {
  var dec = Base32URL.decodeAsString(enc);
  dec = parseFloat(dec.split("|")[1]);
  dec = dec ? dec : 0.0;
  dec = Math.exp(Math.log(dec)**2)-255;
  dec = dec >= -180 ? dec : 0.0;
  dec = dec <= 180 ? dec : 0.0;
  return dec;
}

function Streetview(props) {
  const [updatedTitle, setUpdatedTitle] = useState(false);

  useEffect(() => {
    if(!updatedTitle) {
      document.title = "Geo - Streetview";
      setUpdatedTitle(true);
    }
  }, [setUpdatedTitle, updatedTitle]);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyBP6aK_63-OMwBoGKDlBrrXyLmjLm4LtVY"
  });

  const center = {
    lat: decodeCoordinate(props.params.lat),
    lng: decodeCoordinate(props.params.lng)
  };

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={7}
    >
      <StreetViewPanorama
        position={center}
        visible={true}
        options={{
          "fullscreenControl": false,
          "addressControl": false,
          "motionTrackingControl": false,
          "linksControl": false,
          "showRoadLabels": false,
          "enableCloseButton": false
        }}
      />
    </GoogleMap>
  ) : <></>
}

export default React.memo(Streetview)